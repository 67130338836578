<template>
    <div>
        <ValidationObserver ref="loginForm">
            <div class="container py-5 my-0 my-sm-5 px-4">
                <div class="row">
                    <div class="col-12">
                        <div class="row align-items-center mb-0 pb-5 mb-sm-4">
                            <div class="col-6 col-sm-6 col-md-6 text-left">
                                <div class="logo-wrapper"><img src="../../../assets/img/bau-logo-for-light.svg"/></div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 text-right"><h4 class="mb-0">{{$t('e_registration_system')}}</h4></div>
                        </div>
                    </div>
                    <div class="col-12">
                        <b-alert variant="info" show class="mb-5">
                            <div>{{ $t('online_reg_not_2') }} <a href="mailto:ekayit@bau.edu.tr">ekayit@bau.edu.tr</a> {{ $t('online_reg_not_3') }}
                            </div>
                        </b-alert>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <ValidationProvider name="tcNo" rules="required|length:11" v-slot="{valid, errors}">
                            <b-form-group :label="$t('tc_no')">
                                <b-form-input
                                    v-mask="'###########'"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="loginForm.tcNo"
                                ></b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <ValidationProvider name="mothersName" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('mother_name')">
                                <b-form-input
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-mask="upperAlphaMask"
                                    v-model="loginForm.mothersName"></b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <ValidationProvider name="fathersName" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('father_name')">
                                <b-form-input
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-mask="upperAlphaMask"
                                    v-model="loginForm.fathersName"></b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <ValidationProvider name="birthdate" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('date_of_birth')">
                                <b-form-input
                                    type="date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="loginForm.birthdate"></b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <b-form-group>
                            <div class="d-flex justify-content-end">
                                <b-button type="button" variant="primary" @click="loginSubmit">
                                    {{ $t('check_it') }}
                                </b-button>
                            </div>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <CommonModal ref="payment">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('not_have_payment_approval') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <p>
                        Bilgilerinizi güncellediniz, varsa belgelerinizi yüklediniz, Üniversitemize kaydınız henüz tamamlanmadı, kesin kayıt işlemini tamamlamanız için ödemenizi tamamlamalısınız. Ödemeniz tamamlandıktan sonra “kayıtlı” hale gelebileceksiniz, “kayıtlı” hale geldiğinizde mesaj gönderilecektir.
                    </p>
                    <p>
                        Ödemenizi <a href="/epayment" target="_blank">online ödeme</a> sayfasından yapabilirsiniz.
                    </p>
                    <p>
                        Ödemenizi Havale/EFT ile alttaki hesap numaralarına yapabilirsiniz.
                        Denizbank .....
                    </p>
                </template>
            </CommonModal>
        </ValidationObserver>
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import CommonModal from "@/components/elements/CommonModal";
import moment from "moment";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        CommonModal,
    },
    data() {
        return {
            noLayout: false,

            dateValidate: null,
            CommonModalMode: false,
            date: null,
            masks: {
                input: "DD-MM-YYYY",
            },
            loginForm: {
                tcNo: '10000000000',
                mothersName: 'ANNE',
                fathersName: 'BABA',
                birthdate: '2000-01-01'
            },
            stateData: null,
            upperAlphaMask: {
                mask: 'UUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU',
                tokens: {
                    'U': {
                        pattern: /[A-Za-z ÇĞİÖŞÜçğıöşü]/,
                        transform: function(v) {
                            v=v.replaceAll('i','İ');
                            return v;
                        }
                    }
                }
            },
        }
    },
    watch: {
        date: function () {
            if (this.date) {
                this.dateValidate = "valid";
            }
        },
    },

    methods: {

        validateState(item) {
            const {$dirty, $error} = item;
            return $dirty ? !$error : null;
        },

        async loginSubmit() {
            const isValid = await this.$refs.loginForm.validate();
            if (isValid) {
                this.loginForm.birthdate = moment(this.loginForm.birthdate).format('YYYY-MM-DD');
                let loginForm = {
                    national_id: this.loginForm.tcNo,
                    father_name: this.loginForm.fathersName,
                    mother_name: this.loginForm.mothersName,
                    birthdate: this.loginForm.birthdate
                }
                this.$store.dispatch("onlineRegistration/login", loginForm).then(() => {
                    this.stateData = {
                        ...this.$store.getters['onlineRegistration/getRegistration'],
                        ...this.$store.getters['onlineRegistration/getOrToken'],
                        ...this.$store.getters['onlineRegistration/getFileTypes'],
                        mobileVerify: this.$store.getters['onlineRegistration/getMobileVerify'],
                        emailVerify: this.$store.getters['onlineRegistration/getEmailVerify'],
                        disabledTypes: this.$store.getters['onlineRegistration/getDisabledTypes']
                    };

                    this.$router.push('/online/registration')
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if(e.data.message=='ONLINE_REGISTRATION_COMPLETED'){
                            this.$refs.payment.$refs.commonModal.show();
                        }
                        else if (e.data.message) {
                            this.$toast.error(this.$t('api.'+e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.national_id) {
                            this.$refs.loginForm.errors.tcNo.push(e.data.errors.national_id[0]);
                        }
                        if (e.data.errors.father_name) {
                            this.$refs.loginForm.errors.fathersName.push(e.data.errors.father_name[0]);
                        }
                        if (e.data.errors.mother_name) {
                            this.$refs.loginForm.errors.mothersName.push(e.data.errors.mother_name[0]);
                        }
                        if (e.data.errors.birthdate) {
                            this.$refs.loginForm.errors.birthdate.push(e.data.errors.birthdate[0]);
                        }
                    }
                })
            }
        }

    }

}
</script>
